.login-wrapper {
  height: 100vh;
}

.login-form {
  width: 350px;
  margin: 200px auto;
}

.login-logo {
  padding: 0 0 32px 0;
  text-align: center;
  font-size: 24px;
}

.login-form-item {
  width: 350px;
  margin: 30px auto;
  text-align: left;
}

.login-button {
  width: 165px;
  height: 40px;
  margin: 0 5px;
}

.container-wrapper {
  height: 100vh;
}

.content-wrapper {
  padding: 20px;
}

.top-logo {
  width: 200px;
  float: left;
  color: #fff;
}

.top-action {
  width: 200px;
  float: right;
  text-align: right;
  color: #fff;
}
